#nav {
    background-color: transparent;
    height: 65px;
}

#menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

#menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #4d774e;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: white;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

#menu {
    position: absolute;
    width: 100vw;
    height: 90vh;
    /* box-shadow: 0 0 10px #85888C; */
    margin: -50px 0 0 -50px;
    padding: 50px;
    
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    list-style: none;
    /* background-color: #4d774e; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* align-items: center; */
}

#menu li {
    /* background-color: #4d774e; */
    /* border: solid 1px white; */
    padding: .5rem 2rem;
    margin: .5rem auto;
    /* padding: 1.5rem; */
    transition-delay: 2s;

    height: auto;
    width: 100%;
    opacity: .9;
}

#menu li a {
    /* background-color: #4d774e; */
    /* padding: 2rem; */
    border-radius: 8px;
    color: white;
    /* color: #181818; */
    font-size: 1.5rem;
}

#menuToggle input:checked ~ ul {
    transform: none;
    /* background-color: #f8f8f8; */
}

/* Display full or mobile nav based on screen width */
nav {
    display: block;
}
#nav {
    display: none;
}


/* PAGES */
#home {
    width: 60%; margin: auto; height: 20%; padding-bottom: 10%;
}

#services {
    width: 60%; margin: auto;
}
#services h2 {
    margin-top: 1rem;
}
#services div {
    margin: 2rem auto;
}
#services button {
    margin: 2rem;
}

#work-samples {
    
}

#about {
    width: 60%; margin: auto;
    text-align: left;
}
#about h2 {
    margin-top: 0;
    text-align: center;
}
#about h3 {
    margin: 0 1rem;
    display: inline;
}
#about p {
    margin: 1.5rem auto;
}
#about img {
    width: 160px;
    height: auto;
    margin: 3rem 0 0 0;
    /* border: solid 5px #4d774e; */
    /* border: solid 2px #f8f8f8; */
    /* box-shadow: 0 0 4px #4d774e; */
    /* border-radius: 10%; */
    /* border-radius: 50%; */

    /* -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0))); */
        /* mask-image: radial-gradient(rgba(0,0,0,1), rgba(0,0,0,.5)); */
        /* mask-image: radial-gradient(#4d774e, transparent); */
    /* } */
}

#contact {
    /* width: 50%; margin: auto; */
    width: auto; margin: auto;
}
#contact p, #contact h3 {
    text-align: center;
}

@media screen and (max-width: 1023px) {
    body {
        width: 100vw;
        min-height: 100vh;
    }
    body::after {
        min-height: auto;
        opacity: .125;
    }
    nav {
        display: none;
    }
    #nav {
        display: block;
    }

    .content {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    #home, #services {
        width: 90%;
        height: auto;
        text-align: center;
        margin: auto;
        padding: 0;
    }
    #home .logo {
        width: 260px;
        height: auto;
    }
    .list {
        display: block;
        width: 100%;
    }
    .list li {
        width: 100%;
        margin: 1rem 0;
        height: auto;
        text-align: center;
    }
    .list li a {
        width: 100%;
        font-size: 1.5rem;
    }

    #services ul {
        width: 85%;
    }
    #services ul li {
        height: auto;
    }

    #work-samples {
        width: 98%;
        text-align: center;
    }
    #work-samples .gallery {
        width: 98%;
        margin: 2rem auto;
        padding: 0;
    }
    #work-samples .gallery div {
        display: block;
        width: 90%;
        margin: 1rem auto;
        padding: 0;
    }

    #about {
        width: 90%;
    }

    .row-3 {
        display: block;
    }
    .row-3 div {
        width: 100%;
    }

    form {
        margin: .5rem;
        padding: .5rem;
    }
}